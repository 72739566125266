import { Dispatch, SetStateAction } from "react"
import { verifyMessage } from "viem"

export const message = `Sign this message to prove you have access to the connected wallet. This won't cost you any Ether.

Timestamp: ${Date.now()}`

export async function signMessage(
  accountAddress: `0x${string}`,
  signMessage: (args?: any) => Promise<`0x${string}`>,
  setIsSigned: Dispatch<SetStateAction<boolean>>
) {
  const signature = await signMessage()

  if (verifyMessage({ address: accountAddress, message, signature })) {
    setIsSigned(true)
    localStorage.setItem("isSigned", accountAddress)
  }
}

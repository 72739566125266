import ShoppingBag from "@components/icons/ShoppingBag"
import Units from "@components/icons/Units"
import {
  CardImage,
  CartButton,
  DoubleText,
  EditProductForm,
  MarkdownBlock
} from "@components/ui"
import { useAppContext } from "@components/ui/context"
import { ProductCart } from "@lib/handleUpdateCart"
import formatNumber from "@utils/formatNumber"
import { ethers } from "ethers"
import getFunctionFromSelector from "@utils/getFunctionFromSelector"
import { useEffect, useState } from "react"
import { track } from "@vercel/analytics"
import Share from "@components/icons/Share"
import { domain } from "@components/common/Head"
import copyText from "@utils/copyText"
import Bolt from "@components/icons/Bolt"
import { strategiesList } from "@components/priceStrategies/strategies"
import constants from "constants.json"
import { useRouter } from "next/router"
import useEthUsd from "@utils/useEthUsd"
import formatBlockscanUrl from "@utils/formatBlockscanUrl"
import { availableCurrencies } from "@components/ui/AddProductFormPrice/AddProductFormPrice"
import { formatEther } from "viem"

export const PRODUCT_VIEW = (params: any) => {
  const {
    account,
    dbId,
    slicerId,
    productId,
    name,
    shortDescription,
    description,
    image,
    productPrice,
    isUSD,
    extAddress,
    extValue,
    extCheckSig,
    extExecSig,
    isInfinite,
    maxUnits,
    uid,
    creator,
    texts,
    allowedAddresses,
    availableUnits,
    totalPurchases,
    purchaseInfo,
    slicerAddress,
    price,
    editMode,
    purchasedQuantity,
    availabilityColor,
    preview,
    externalPriceAddress,
    externalPrices,
    isCustomPriced,
    currency,
    extRelativePrice,
    isDelivery
  } = params

  const { cart } = useAppContext()
  const router = useRouter()
  const { id } = router.query
  const ethUsd = useEthUsd()
  const [isCopied, setIsCopied] = useState(false)
  const [customMessage, setCustomMessage] = useState("")
  const productCart: ProductCart = cart.find(
    (product) =>
      product.slicerAddress == slicerAddress && product.productId == productId
  )
  const purchaseElArray = Object.keys(purchaseInfo).filter(
    (el) => el != "redeemButton" && purchaseInfo[el] == true
  )
  const purchaseEl = purchaseElArray.join(", ")

  const strategyAddresses =
    constants[process.env.NEXT_PUBLIC_CHAIN_ID][
      process.env.NEXT_PUBLIC_ENVIRONMENT
    ].strategies

  const strategy = Object.values(strategiesList).find(
    ({ label }) =>
      strategyAddresses[label]?.toLowerCase() ==
      externalPriceAddress?.toLowerCase()
  )

  const priceLabel =
    currency === ethers.constants.AddressZero
      ? productPrice?.eth != "Free"
        ? productPrice.eth
        : "Free"
      : productPrice?.usdc != "Free"
      ? productPrice.usdc
      : "Free"

  // const cafeId =
  //   constants[process.env.NEXT_PUBLIC_CHAIN_ID][
  //     process.env.NEXT_PUBLIC_ENVIRONMENT
  //   ].constants["slicerIdCafe"];

  // useEffect(() => {
  // track("product_view_open_modal")
  // }, [])

  return (
    <>
      <div
        className="absolute top-[24px] left-[24px] hover:text-blue-600 flex cursor-pointer items-center text-gray-700"
        onClick={async () =>
          await copyText(
            `${domain}/slicer/${slicerId}/products/${productId}`,
            setIsCopied
          )
        }
      >
        <Share />
        <p className="pt-0.5 pl-2 text-sm font-semibold">
          {isCopied ? "Link copied! 🍰" : "Share"}
        </p>
      </div>
      <div className="pt-4 pb-12 text-center sm:pb-16">
        <DoubleText inactive logoText={name} size="text-3xl sm:text-4xl" />
        {shortDescription && (
          <p className="max-w-sm pt-6 mx-auto text-gray-500">
            {shortDescription}
          </p>
        )}
      </div>
      <div>
        <CardImage
          name={name}
          imageUrl={image}
          size="h-52 xs:h-72"
          disableHover
          product
          topLeft={{
            title: "Purchases",
            content: (
              <>
                <p className="mr-2 text-indigo-600">
                  {totalPurchases ? formatNumber(totalPurchases) : 0}
                </p>
                <ShoppingBag className="w-[18px] h-[18px] text-indigo-600" />
              </>
            )
          }}
          topRight={
            ethUsd && {
              title: "Product price",
              content: (
                <div className="flex items-center justify-center">
                  {isCustomPriced && (
                    <div className="w-5 h-5 mr-2 -ml-1 text-yellow-500 animate-pulse">
                      <Bolt />
                    </div>
                  )}
                  <p
                    className={`text-sm capitalize font-medium text-black${
                      productPrice.usd == "Free" ? " text-green-600" : ""
                    }`}
                  >
                    {productPrice.usd}
                  </p>
                </div>
              )
            }
          }
          bottomLeft={
            extAddress &&
            !isInfinite && {
              title: "Available units",
              content: (
                <>
                  <p className={`mr-2 ${availabilityColor}`}>
                    {formatNumber(availableUnits)}
                  </p>
                  <Units className={`w-[18px] h-[18px] ${availabilityColor}`} />
                </>
              )
            }
          }
        />
        {editMode ? (
          <div className="pt-8">
            <EditProductForm
              slicerId={slicerId}
              productId={productId}
              maxUnits={maxUnits}
              isInfinite={isInfinite}
              availableUnits={availableUnits}
              productPrice={productPrice}
              isUSD={isUSD}
              externalPriceAddress={externalPriceAddress}
              currency={currency}
            />
          </div>
        ) : (
          <div className="pt-8 pb-4">
            <MarkdownBlock content={description} />
          </div>
        )}
        {/* {extAddress &&
          (!isCustomPriced ||
            (externalPrices &&
              externalPrices[slicerId] &&
              externalPrices[slicerId][productId])) && (
            <> */}
        {
          !editMode && (
            <>
              <div className="pb-2 mx-auto cursor-pointer w-72">
                {/* {cafeId && slicerId == cafeId && (
                <Input
                  label="Table / ref"
                  value={customMessage}
                  onChange={setCustomMessage}
                />
              )} */}
                <CartButton
                  isDelivery={isDelivery}
                  slicerId={slicerId}
                  productCart={productCart}
                  slicerAddress={slicerAddress}
                  productId={productId}
                  price={
                    isCustomPriced &&
                    externalPrices &&
                    externalPrices[slicerId] &&
                    externalPrices[slicerId][productId]
                      ? externalPrices[slicerId][productId][currency][
                          currency === ethers.constants.AddressZero
                            ? "ethPrice"
                            : "currencyPrice"
                        ]
                      : price
                  }
                  currency={currency}
                  isUSD={isCustomPriced ? false : isUSD}
                  extAddress={extAddress}
                  extCallValue={extValue}
                  extCheckSig={extCheckSig}
                  name={name}
                  image={image}
                  maxUnits={Number(maxUnits)}
                  availableUnits={isInfinite ? -1 : availableUnits}
                  purchasedQuantity={purchasedQuantity}
                  uid={uid}
                  creator={creator}
                  texts={texts}
                  redeemButton={purchaseInfo?.redeemButton}
                  allowedAddresses={allowedAddresses}
                  labelAdd={priceLabel}
                  labelRemove={priceLabel}
                  preview={preview}
                  shortcodes={purchaseInfo?.shortcodes}
                  dbId={dbId}
                  externalPriceAddress={externalPriceAddress}
                  customMessage={customMessage}
                  extRelativePrice={extRelativePrice}
                />
              </div>
              {productPrice.eth != "Free" &&
                Number(maxUnits) != 1 &&
                productCart?.quantity && (
                  <p className="py-2 text-sm text-center ">
                    {`Ξ ${
                      Math.floor(
                        Number(productPrice.eth.substring(1)) *
                          productCart?.quantity *
                          1000
                      ) / 1000
                    }`}
                  </p>
                )}
              {isCustomPriced && externalPriceAddress && (
                <p className="pt-4 mx-auto text-sm text-center text-gray-500">
                  Price dynamically calculated from{" "}
                  <a
                    className="font-bold highlight"
                    href={formatBlockscanUrl(externalPriceAddress)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {externalPriceAddress.replace(
                      externalPriceAddress.substring(
                        5,
                        externalPriceAddress.length - 3
                      ),
                      `\xa0\xa0\xa0\xa0\xa0\xa0`
                    )}
                  </a>
                  {strategy && ` (${strategy.label})`}
                </p>
              )}
              {extAddress &&
              extAddress != "0x00000000" &&
              extAddress != ethers.constants.AddressZero &&
              (extValue != "0" || extExecSig != "0x00000000") ? (
                <p className="pt-4 mx-auto text-sm text-center text-gray-500">
                  Interacts with{" "}
                  <a
                    className="font-bold highlight"
                    href={formatBlockscanUrl(extAddress)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {extAddress.replace(
                      extAddress.substring(5, extAddress.length - 3),
                      `\xa0\xa0\xa0\xa0\xa0\xa0`
                    )}
                  </a>{" "}
                  by{" "}
                  {extValue != "0" ? (
                    <>
                      sending{" "}
                      <b>
                        {currency.toLowerCase() ===
                        availableCurrencies[0].value.toLowerCase()
                          ? `${
                              Math.round(
                                Number(
                                  formatEther(
                                    extRelativePrice
                                      ? (BigInt(price || 0) *
                                          BigInt(extValue)) /
                                          BigInt(1e4)
                                      : BigInt(extValue)
                                  )
                                ) * 1e4
                              ) / 1e4
                            } ETH`
                          : `${formatNumber(
                              extRelativePrice
                                ? Math.round(
                                    Number(BigInt(price) * BigInt(extValue)) /
                                      1e8
                                  ) / 100
                                : extValue
                            )} USDC`}
                      </b>
                    </>
                  ) : (
                    ""
                  )}
                  {extValue != "0" && extExecSig != "0x00000000" ? " and " : ""}
                  {extExecSig != "0x00000000" ? (
                    <>
                      executing <b>{getFunctionFromSelector(extExecSig)}</b>
                    </>
                  ) : (
                    ""
                  )}
                </p>
              ) : null}
              {purchaseElArray && purchaseElArray.length != 0 ? (
                <p className="pt-4 text-gray-500 text-sm text-center mx-auto max-w-[340px]">
                  Contains <b>{purchaseEl}</b>
                </p>
              ) : null}
            </>
          )
          //  : (
          //   <p className="text-center text-gray-600">
          //     <Link
          //       href={`https://${getSliceSubdomain()}redeem.slice.so/create?slicer=${slicerId}&product=${productId}`}
          //       className="highlight"
          //       target="_blank"
          //       rel="noreferrer"
          //     >
          //       Set redeem form
          //     </Link>
          //   </p>
          // )
        }
      </div>
    </>
  )
}

export default PRODUCT_VIEW

import "react-day-picker/dist/style.css"
import { useContractWrite, usePrepareContractWrite } from "wagmi"
import constants from "constants.json"
import ProductsModuleContract from "artifacts/contracts/ProductsModule.sol/ProductsModule.json"
import Button from "../Button"
import { useState } from "react"
import executeTransaction from "@utils/executeTransaction"
import { useAddRecentTransaction } from "@rainbow-me/rainbowkit"

export default function CloseStore({ storeClosed, setStoreClosed, slicerId }) {
  const [loading, setLoading] = useState(false)

  const addRecentTransaction = useAddRecentTransaction()
  const { config } = usePrepareContractWrite({
    address:
      constants[process.env.NEXT_PUBLIC_CHAIN_ID][
        process.env.NEXT_PUBLIC_ENVIRONMENT
      ].addresses.ProductsModule,
    abi: ProductsModuleContract.abi,
    functionName: "_setStoreConfig",
    args: [slicerId, !storeClosed, 0]
  })
  const { writeAsync } = useContractWrite(config)

  return (
    <div className="ml-4 text-left">
      <h2 className="mb-2 text-lg font-semibold">Open and close store</h2>
      <p className="mb-4">
        Enable / disable all product purchases on this store.
      </p>
      <div>
        <div className="space-y-8 sm:space-y-0 sm:flex sm:gap-6">
          <Button
            label={storeClosed ? "Open store" : "Close store"}
            disabled={storeClosed == null}
            loading={storeClosed == null || loading}
            onClick={async () =>
              executeTransaction(
                writeAsync,
                setLoading,
                storeClosed ? "Open store" : "Close store",
                addRecentTransaction,
                () => setStoreClosed(!storeClosed)
              )
            }
          />
        </div>
      </div>
    </div>
  )
}

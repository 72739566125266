import { useEffect, useState } from "react"
import {
  strategiesList,
  Strategy,
  StrategyParams,
  StrategyProps
} from "../strategies"
import constants from "constants.json"
import { DiscountCurrency } from "."
import {
  formatEther,
  formatUnits,
  isAddress,
  parseEther,
  parseUnits
} from "viem"
import { availableCurrencies } from "@components/ui/AddProductFormPrice/AddProductFormPrice"
import { DiscountParams } from "../NFTDiscount/NFTDiscount"

const label = "Friend Tech Keys Discount"
const description =
  "Offer discounts based on Friend Tech keys owned by the buyer"
const strategyLabel = "KeysDiscount"

const Component = ({
  priceParams,
  setPriceParams,
  productCurrency
}: StrategyProps) => {
  const initParams =
    (priceParams.args && priceParams.args[0] && priceParams.args[0][0]) || []
  const [discountParams, setDiscountParams] = useState<DiscountParams>({
    basePrice: initParams[1]
      ? Number(
          initParams[0] == availableCurrencies[1].value
            ? formatUnits(initParams[1], 6)
            : formatEther(initParams[1])
        )
      : 0,
    discounts: initParams[4]?.length
      ? initParams[4].map((el: any) => ({ ...el, discount: el.discount / 100 }))
      : [
          {
            nft: "",
            discount: 0,
            minQuantity: 1
          }
        ]
  })

  const strategy = strategiesList[strategyLabel]

  useEffect(() => {
    const { label, abi } = strategy
    const address =
      constants[process.env.NEXT_PUBLIC_CHAIN_ID][
        process.env.NEXT_PUBLIC_ENVIRONMENT
      ].strategies[label]

    // filter and sort discounts in descending order
    const cleanedDiscounts = discountParams.discounts
      .filter((el) => isAddress(el.nft) && el.discount && el.minQuantity)
      .sort((a, b) => b.discount - a.discount)
      .map((el) => ({ ...el, discount: el.discount * 100 }))

    const newPriceParams: StrategyParams = {
      ...priceParams,
      label,
      address,
      abi,
      args: [
        [
          [
            productCurrency,
            productCurrency == availableCurrencies[1].value
              ? parseUnits(discountParams.basePrice.toString(), 6)
              : parseEther(discountParams.basePrice.toString()),
            false, // isFree
            1, // Relative DiscountType
            cleanedDiscounts
          ]
        ]
      ]
    }

    setPriceParams(newPriceParams)
  }, [discountParams, productCurrency])

  return (
    <>
      <DiscountCurrency
        discountParams={discountParams}
        setDiscountParams={setDiscountParams}
        currency={productCurrency}
      />
    </>
  )
}

const hook: Strategy = { label, description, Component }

export default hook

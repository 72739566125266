import { sliceCoreAddress } from "@lib/initProvider"
import { usePrepareContractWrite } from "wagmi"
import SliceCore from "artifacts/contracts/SliceCore.sol/SliceCore.json"

const usePrepareSliceTransfer = (
  batchMode: boolean,
  account: string,
  addresses: string[],
  slicerId: number,
  shares: number[],
  toRelease: boolean
) => {
  return usePrepareContractWrite({
    address: sliceCoreAddress,
    abi: SliceCore.abi,
    functionName: (() => {
      if (batchMode) return "slicerBatchTransfer"
      return toRelease ? "safeTransferFrom" : "safeTransferFromUnreleased"
    })(),
    args: (() => {
      if (batchMode) {
        return [account, addresses, slicerId, shares, toRelease]
      }
      return [account, addresses[0], slicerId, shares[0], ""]
    })()
  })
}

export default usePrepareSliceTransfer

import { useCallback, useEffect, useState } from "react"
import multicall from "@utils/multicall"
import { ethers } from "ethers"
import { BlockchainProduct } from "pages/slicer/[id]"
import productsModule from "artifacts/contracts/ProductsModule.sol/ProductsModule.json"
import { productsModuleAddress } from "./initProvider"
import { Abi } from "viem"

export const getExternalPrices = async (
  args: string[][],
  ids: [number, number][],
  currencies: string[]
) => {
  const returnedPrices = {}
  const data = await multicall(
    productsModuleAddress,
    productsModule.abi as Abi,
    "productPrice",
    args
  )

  ids.forEach(([slicerId, productId], i) => {
    if (data[i]?.status == "success") {
      const { eth: ethPrice, currency: currencyPrice } = data[i].result as any

      if (!returnedPrices[slicerId]) returnedPrices[slicerId] = {}
      returnedPrices[slicerId][productId] = {
        [currencies[i]]: {
          ethPrice,
          currencyPrice
        }
      }
    }
  })

  return returnedPrices
}

const formatArgs = (
  account: string,
  blockchainProducts: BlockchainProduct[]
) => {
  const ids = []
  const args = []
  const currencies = []

  if (blockchainProducts.length != 0) {
    blockchainProducts.forEach((blockchainProduct) => {
      if (
        blockchainProduct.prices.length != 0 &&
        blockchainProduct.prices[0].externalAddress != "0x00000000" &&
        blockchainProduct.prices[0].externalAddress !=
          ethers.constants.AddressZero
      ) {
        const [slicerId, productId] = blockchainProduct.id.split("-")
        const currency = blockchainProduct.prices[0].currency.id

        ids.push([Number(slicerId), Number(productId)])
        args.push([
          slicerId,
          productId,
          currency,
          1,
          // account || ethers.constants.AddressZero,
          account || "0x0000000000000000000000000000000000000001", // use address 1 to prevent eventual reverts
          ""
        ])
        currencies.push(currency)
      }
    })
  }

  return [ids, args, currencies]
}

const useExternalPrices = (
  account: string,
  blockchainProducts: BlockchainProduct[]
) => {
  const [prices, setPrices] = useState({})

  const getPrices = useCallback(async () => {
    const [ids, args, currencies] = formatArgs(account, blockchainProducts)
    if (args.length != 0) {
      setPrices(await getExternalPrices(args, ids, currencies))
    }
  }, [account, blockchainProducts])

  // This will run when 'account' changes, instantly fetching the prices
  useEffect(() => {
    getPrices()
  }, [account, getPrices])

  // This sets up the interval for getting prices
  useEffect(() => {
    const intervalId = setInterval(() => {
      getPrices()
    }, 30000)

    return () => {
      clearInterval(intervalId)
    }
  }, [getPrices])

  return prices
}

export default useExternalPrices

// TODO: FIX cart button not disappearing in products with invalid dynamic price (not here)
// TODO: Change multicall, getPrices and useExternalPrices to use wagmi hooks (needed also to handle reverts and reduce complexity)

import Button from "../Button"
import Login from "@components/icons/Login"
import { useConnect, useSIWE } from "@walletkit/wagmi-v1-link"
import { useAppContext } from "../context"
import { useAccount, useDisconnect } from "wagmi"
import { ConnectButton } from "@rainbow-me/rainbowkit"
import { useWalletKitLink } from "@walletkit/react-link"
import UserIcon from "@components/icons/UserIcon"

const connectText = "Go gasless" // "Login with Slice" // "Go Gasless" || "Use Slice Wallet"

type Props = {
  size?: "small" | "large"
}

const SliceConnectButton = ({ size = "small" }: Props) => {
  const { isConnected } = useAppContext()
  const { connectAsync } = useConnect()
  const { connector } = useAccount()
  const { disconnectAsync } = useDisconnect()
  const { siweAsync } = useSIWE()
  const walletKit = useWalletKitLink()

  return (
    <>
      {isConnected && connector?.id !== "walletKitLink" ? (
        <ConnectButton
          accountStatus={{
            smallScreen: "avatar",
            largeScreen: "full"
          }}
          chainStatus="none"
        />
      ) : null}
      <Button
        className={
          size == "small"
            ? "h-9 font-bold tracking-wide m-[2px] rounded-full overflow-hidden px-6 focus:outline-none"
            : undefined
        }
        rounded={size == "small" ? "rounded-full" : undefined}
        label={
          isConnected && connector?.id === "walletKitLink" ? (
            <div className="flex items-center gap-2 text-sm">
              <p>My wallet</p>
              <UserIcon className="w-[18px] h-[18px] mt-[1px]" />
            </div>
          ) : isConnected ? null : (
            // ? <><p>{connectText}</p><Login className="w-[18px] h-[18px] mt-[1px]" /></> :
            <div className="flex items-center gap-2 text-sm">
              <p>Login</p>
              <Login className="w-[18px] h-[18px] mt-[1px]" />
            </div>
          )
        }
        onClick={async () => {
          try {
            if (!isConnected) {
              await connectAsync()
              return
            }

            if (connector?.id === "walletKitLink") {
              walletKit.openWallet({ onDisconnect: disconnectAsync })
            } else {
              await siweAsync()
            }
          } catch (e) {
            console.error("Failed to connect", e)
          }
        }}
      />
    </>
  )
}

export default SliceConnectButton

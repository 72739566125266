import { NewTransaction } from "@rainbow-me/rainbowkit/dist/transactions/transactionStore"
import { Dispatch, SetStateAction } from "react"
import { waitForTransaction, WriteContractResult } from "@wagmi/core"

export type TxData = {
  transactionHash?: string
}

const executeTransaction = async (
  promise: () => Promise<WriteContractResult>,
  setLoading?: Dispatch<SetStateAction<boolean>> | undefined,
  txDescription?: string,
  addRecentTransaction?: (transaction: NewTransaction) => void,
  settlementLogic?: any,
  setData?: Dispatch<SetStateAction<any>>
) => {
  setLoading?.(true)
  setData?.(null)

  try {
    const tx = await promise()

    addRecentTransaction?.({
      hash: tx.hash,
      description: txDescription || "Transaction executed"
    })

    const waitData = await waitForTransaction({
      hash: tx.hash
    })

    settlementLogic?.()
    setData?.(waitData)
    setLoading?.(false)

    return waitData
  } catch (err) {
    console.log(err)
  }
  setLoading?.(false)
}

export default executeTransaction

import Link from "next/link"
import fetcher from "@utils/fetcher"
import useSWR from "swr"
import { useEffect, useState } from "react"
import formatNumber from "@utils/formatNumber"
import Arrow from "@components/icons/Arrow"
import { CardImage, CopyAddress, WithdrawButton } from ".."
import UserVerified from "@components/icons/UserVerified"
import Immutable from "@components/icons/Immutable"
import { ethers } from "ethers"
import getEthFromWei from "@utils/getEthFromWei"
import { UnreleasedAmount } from "pages/api/account/[address]/unreleased"
import { formatUnits } from "viem"
import Settings from "@components/icons/SettingsIcon"
import { usdc } from "../WithdrawButton/WithdrawButton"

type SlicerInfo = {
  name: string
  address: string
  image: string
}

type Props = {
  slicerId: number
  slicerAddress: string
  shares: number
  totalSlices: number
  protocolFee: number
  account: string
  isAllowed: boolean
  isImmutable: boolean
  unreleasedAmount: UnreleasedAmount
}

const dashboardDomain = process.env.NEXT_PUBLIC_DASHBOARD_APP_URL

const SlicerCard = ({
  slicerId,
  slicerAddress,
  account,
  shares,
  totalSlices,
  protocolFee,
  isAllowed,
  isImmutable,
  unreleasedAmount
}: Props) => {
  const { data: slicerInfo } = useSWR(
    `/api/slicer/${slicerId}?stats=false`,
    fetcher
  )

  const { name, image }: SlicerInfo = slicerInfo || {
    name: null,
    image: null
  }

  const [ethReleased, setEthReleased] = useState("")
  const [usdcReleased, setUsdcReleased] = useState("")
  const [ethSuccess, setEthSuccess] = useState(false)
  const [usdcSuccess, setUsdcSuccess] = useState(false)
  // const [logs, setLogs] = useState<LogDescription[]>()
  const slicerLink = `/slicer/${slicerId}`
  const slicerName = name || `Slicer #${slicerId}`
  const slicePercentage = `${Math.floor((shares / totalSlices) * 10000) / 100}%`

  useEffect(() => {
    if (ethSuccess) {
      setEthReleased(String(getEthFromWei(unreleasedAmount?.eth)))
    }
  }, [ethSuccess])

  useEffect(() => {
    if (usdcSuccess) {
      setUsdcReleased(formatUnits(BigInt(unreleasedAmount?.usdc), 6))
    }
  }, [usdcSuccess])

  return (
    <div className="sm:flex">
      <CardImage
        href={slicerLink}
        name={slicerName}
        topLeft={
          isImmutable && {
            title: "Immutable asset",
            content: (
              <Immutable className="py-2 text-indigo-600 w-[38px] h-[38px]" />
            ),
            padding: "px-4"
          }
        }
        topRight={
          isAllowed && {
            title: "Superowner",
            content: (
              <UserVerified className="text-green-500 py-2 w-[38px] h-[38px]" />
            ),
            padding: "px-4"
          }
        }
        bottomLeft={{
          className: "text-black",
          content: slicerAddress ? (
            <CopyAddress
              slicerAddress={slicerAddress as `0x${string}`}
              showIcon={false}
              position="left-0 bottom-[40px]"
            />
          ) : (
            <div className="w-24 h-4 rounded-md bg-sky-300 animate-pulse" />
          ),
          clickable: false
        }}
        bottomRight={{
          title: "Total slices",
          content: `${formatNumber(totalSlices)} 🍰`,
          className: "text-black text-sm font-medium"
        }}
        imageUrl={image}
      />
      <div className="w-full pt-5 sm:pt-4 sm:ml-6 md:ml-14">
        <div className="flex justify-between mb-1">
          <Link href={slicerLink} className="mr-2">
            {slicerInfo ? (
              <span className="text-xl font-medium">{slicerName}</span>
            ) : (
              <div className="inline-block w-32 h-6 rounded-md bg-sky-300 animate-pulse" />
            )}
            <span className="h-full ml-3 text-sm font-normal text-gray-500">
              #{slicerId}
            </span>
          </Link>

          <Link
            href={dashboardDomain + "/store/" + slicerId + "/products"}
            className="w-5 h-5 mt-2 text-gray-500 cursor-pointer hover:text-black"
          >
            <Settings className="w-5 h-5" />
          </Link>
        </div>
        <div className="space-y-2 text-gray-700">
          <div className="flex items-center">
            <p className="text-sm">
              {formatNumber(shares, 3)} slices owned ({slicePercentage})
            </p>
            <Link
              href={`/transfer?id=${slicerId}`}
              className="flex items-center ml-3 group"
            >
              <p className="text-sm ">Transfer</p>
              <div className="w-5 h-5 ml-1 transition-transform duration-150 group-hover:translate-x-1">
                <Arrow />
              </div>
            </Link>
          </div>
          {/* <div className="flex items-center">
            <p className="text-sm">{protocolFee / 10}% protocol fee</p>
            {isAllowed && (
              <a
                className="flex items-center ml-3 group"
                onClick={() =>
                  setModalView({
                    name: "FEE_CHANGE_VIEW",
                    cross: true,
                    params: {
                      slicerId,
                      slicerAddress,
                      protocolFee
                    }
                  })
                }
              >
                <p className="text-sm">Change</p>
                <div className="w-5 h-5 ml-1 transition-transform duration-150 group-hover:translate-x-1">
                  <Arrow />
                </div>
              </a>
            )}
          </div> */}
        </div>
        {/* <ProductsBalance
          slicerId={slicerId}
          productsModuleBalance={productsModuleBalance}
        /> */}
        <div className="mt-6 space-y-3">
          {!ethReleased ? (
            unreleasedAmount?.eth && unreleasedAmount?.eth != "0" ? (
              <WithdrawButton
                slicerId={slicerId}
                slicerAddress={slicerAddress}
                account={account}
                currency={ethers.constants.AddressZero}
                unreleasedAmount={unreleasedAmount?.eth}
                setSuccess={setEthSuccess}
              />
            ) : null
          ) : (
            <p className="text-sm text-green-500">
              You have withdrawn{" "}
              <span className="font-medium">{ethReleased} ETH</span> to your
              wallet!
              {/* check{" "}<Link href="/earnings" className="text-green-500 underline">
              your earnings
            </Link>{" "}
            to withdraw them! */}
            </p>
          )}
          {!usdcReleased ? (
            unreleasedAmount?.usdc && unreleasedAmount?.usdc != "0" ? (
              <WithdrawButton
                slicerId={slicerId}
                slicerAddress={slicerAddress}
                account={account}
                currency={usdc}
                unreleasedAmount={unreleasedAmount?.usdc}
                setSuccess={setUsdcSuccess}
              />
            ) : null
          ) : (
            <p className="text-sm text-green-500">
              You have withdrawn{" "}
              <span className="font-medium">{usdcReleased} USDC</span> to your
              wallet!
              {/* check{" "}<Link href="/earnings" className="text-green-500 underline">
          your earnings
        </Link>{" "}
        to withdraw them! */}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default SlicerCard

import { Analytics } from "@vercel/analytics/react"
import { Background, Layout } from "@components/ui"
import { AppWrapper } from "@components/ui/context"
import { ThemeProvider } from "next-themes"
import Head from "@components/common/Head"
import { AppProps } from "next/dist/shared/lib/router/router"
import "../styles/global/styles.scss"

import {
  RainbowKitProvider,
  lightTheme,
  midnightTheme,
  connectorsForWallets
} from "@rainbow-me/rainbowkit"
import { alchemyProvider } from "wagmi/providers/alchemy"
import { infuraProvider } from "wagmi/providers/infura"
import { publicProvider } from "wagmi/providers/public"
import { createConfig, configureChains, WagmiConfig, Chain } from "wagmi"
import { baseGoerli, goerli, mainnet, optimism } from "wagmi/chains"
import "@rainbow-me/rainbowkit/styles.css"
import { base } from "@utils/chains"
import {
  walletConnectWallet,
  rainbowWallet,
  coinbaseWallet,
  rabbyWallet,
  metaMaskWallet
} from "@rainbow-me/rainbowkit/wallets"
import { MetaMaskConnector } from "@wagmi/core/connectors/metaMask"
import { InjectedConnector } from "@wagmi/core"
import { CoinbaseWalletConnector } from "@wagmi/core/connectors/coinbaseWallet"
import { WalletConnectConnector } from "@wagmi/core/connectors/walletConnect"
import Logo from "@components/icons/Logo"
import { alchemyId } from "@utils/alchemy"
import {
  WalletKitLinkConnector,
  WalletKitLinkWagmiProvider
} from "@walletkit/wagmi-v1-link"
import { WalletKitLinkProvider } from "@walletkit/react-link"

const infuraId = String(process.env.NEXT_PUBLIC_INFURA_ID)
const projectId = "5a80d31c30612dff650054ba58e9748f"

const chainId = Number(process.env.NEXT_PUBLIC_CHAIN_ID)
const customChains: () => Chain[] = () => {
  if (chainId == 1) {
    return [mainnet]
  } else if (chainId == 5) {
    return [goerli]
  } else if (chainId == 10) {
    return [optimism]
  } else if (chainId == 8453) {
    return [base, mainnet]
  } else if (chainId == 84531) {
    return [baseGoerli, goerli]
  }
}

const { chains, publicClient, webSocketPublicClient } = configureChains(
  customChains(),
  [
    alchemyProvider({ apiKey: alchemyId }),
    infuraProvider({ apiKey: infuraId }),
    publicProvider()
  ]
)

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      coinbaseWallet({ chains, appName: "Slice" }),
      rabbyWallet({ chains }),
      rainbowWallet({ projectId, chains }),
      metaMaskWallet({ projectId, chains }),
      walletConnectWallet({
        projectId,
        chains
      })
    ]
  }
  // {
  //   groupName: "Social",
  //   wallets: [
  //     new WalletKitConnector({
  //       options: {}
  //     })
  //   ]
  // }
])

const wagmiConfig = createConfig({
  autoConnect: true,
  // connectors,
  connectors: [
    new InjectedConnector({ chains }),
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "Slice"
      }
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId
      }
    }),
    new WalletKitLinkConnector({
      chains,
      options: {
        projectId: process.env.NEXT_PUBLIC_WALLETKIT_PROJECT_ID,
        appLogoUrl: "https://static.walletkit.com/slice-logo.svg",
        promptForWalletKitWithEOA: true
      }
    })
  ],
  publicClient,
  webSocketPublicClient
})

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head />
      <ThemeProvider
        attribute="class"
        storageKey="nightwind-mode"
        defaultTheme="system"
      >
        <WagmiConfig config={wagmiConfig}>
          <WalletKitLinkWagmiProvider
            externalConnectorIds={[
              "injected",
              "metaMask",
              "coinbaseWallet",
              "rainbow",
              "walletConnect"
            ]}
            layout={{ preferExternalConnectors: true }}
          >
            <RainbowKitProvider
              chains={chains}
              theme={
                // isDark
                //   ? midnightTheme({
                //       accentColor: "#2563eb",
                //       accentColorForeground: "white",
                //       borderRadius: "medium"
                //     })
                //   :
                lightTheme({
                  accentColor: "#2563eb",
                  accentColorForeground: "white",
                  borderRadius: "medium"
                })
              }
              showRecentTransactions={true}
            >
              <AppWrapper>
                <Layout>
                  <Background />
                  <Component {...pageProps} />
                </Layout>
              </AppWrapper>
            </RainbowKitProvider>
          </WalletKitLinkWagmiProvider>
        </WagmiConfig>
      </ThemeProvider>
      <Analytics />
    </>
  )
}

export default MyApp

import dynamic from "next/dynamic"
import Link from "next/link"
import Logo from "@components/icons/Logo"
import Nightwind from "@components/icons/Nightwind"
import { Container, SliceConnectButton } from "@components/ui"
import { useAppContext } from "@components/ui/context"
import { useEffect, useRef, useState } from "react"
import { useAccount, useConnect, useNetwork } from "wagmi"
import { useSIWE } from "@walletkit/wagmi-v1-link"
import Menu from "@components/icons/Menu"
import useQuery from "@utils/subgraphQuery"

const DropdownMenu = dynamic(() => import("@components/ui/DropdownMenu"), {
  ssr: false
})

const Navbar = () => {
  const { isConnected } = useAppContext()
  const [showDropdown, setShowDropdown] = useState(false)
  const dropdownRef = useRef(null)
  const { chain } = useNetwork()
  const isOnCorrectChain =
    Number(chain?.id) === Number(process.env.NEXT_PUBLIC_CHAIN_ID)
  const { connect, connectors } = useConnect()

  const { connector: signerConnector } = useSIWE()
  const { connector } = useAccount({
    onDisconnect: () => {
      if (signerConnector && connectors.find((c) => c == signerConnector)) {
        connect({ connector: signerConnector })
      }
    }
  })

  const { account } = useAppContext()
  const tokensQuery = /* GraphQL */ `
  payee(id: "${account?.toLowerCase()}") {
    slicers (
      where: {slices_gt: "0"}
    ) {
      slices
      slicer {
        id
        minimumSlices
      }
    }
  }
`
  let subgraphData = useQuery(tokensQuery, [account])
  const slicers = subgraphData?.payee?.slicers || []
  const ownedSlicers = slicers?.filter(
    (slicer: any) => Number(slicer.slices) > Number(slicer.slicer.minimumSlices)
  )

  useEffect(() => {
    function handleClick(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClick)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClick)
    }
  }, [dropdownRef])

  return (
    <header className="shadow-sm">
      <Container>
        <nav className="relative flex items-center justify-between h-20 px-3 mx-auto sm:px-6">
          <div className="z-10 flex items-center space-x-6 sm:space-x-8">
            <Link href="/" aria-label="Slice logo">
              <Logo className="w-6 h-6" />
            </Link>
            <Link href="/slicer">
              <p className="text-[0.925rem] font-medium">Explore</p>
            </Link>
          </div>
          <div className="relative z-10 flex items-center space-x-6">
            <div>
              <Nightwind size="h-[24px]" />
            </div>
            {isConnected ? (
              <>
                <SliceConnectButton />
                <a
                  className="text-gray-600"
                  onClick={() =>
                    setShowDropdown((showDropdown) => !showDropdown)
                  }
                  ref={dropdownRef}
                >
                  <Menu />
                </a>
              </>
            ) : (
              <div className="text-sm">
                <SliceConnectButton />
              </div>
            )}
          </div>
          {showDropdown && (
            <div className="absolute top-0 right-0" ref={dropdownRef}>
              <DropdownMenu
                setShowDropdown={setShowDropdown}
                ownedSlicers={ownedSlicers}
              />
            </div>
          )}
        </nav>
      </Container>
    </header>
  )
}

export default Navbar

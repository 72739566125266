import DoubleText from "../DoubleText"

const SliceFormDescription = () => {
  return (
    <>
      <div className="py-4 mx-auto space-y-4 sm:px-6 max-w-screen-xs md:text-left">
        <div className="pb-4 prose">
          <p>
            Onchain stores split revenue based on the number of slices owned.
            Choose the initial owners of the store below.
          </p>
          {/* <p>
            <b>Slices 🍰</b> are transferable ERC1155 tokens which represent
            ownership over the slicer and its earnings.
          </p> */}
        </div>
      </div>
    </>
  )
}

export default SliceFormDescription

import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { readContracts } from "wagmi"
import erc165 from "artifacts/contracts/ERC165.json"
import {
  DiscountParams,
  NFTType
} from "@components/priceStrategies/NFTDiscount/NFTDiscount"

type NFTStringType = "ERC721" | "ERC1155" | ""

const update = async (
  index: number,
  address: `0x${string}`,
  setNftType: Dispatch<SetStateAction<NFTStringType>>,
  setDiscountParams: Dispatch<SetStateAction<DiscountParams>>
) => {
  const handleSetNftType = (index: number, value: NFTType) => {
    setDiscountParams((discountParams) => {
      const items = discountParams.discounts
      items[index].nftType = value

      return {
        ...discountParams,
        discounts: items
      }
    })
  }

  const nftContract = {
    address: address,
    abi: erc165.abi
  }

  const data = await readContracts({
    contracts: [
      //@ts-ignore
      {
        ...nftContract,
        functionName: "supportsInterface",
        args: ["0x80ac58cd"]
      },
      //@ts-ignore
      {
        ...nftContract,
        functionName: "supportsInterface",
        args: ["0xd9b67a26"]
      }
    ]
  })

  const [isERC721, isERC1155] = data?.map((el) => el?.result)

  setNftType(isERC721 ? "ERC721" : isERC1155 ? "ERC1155" : "")
  handleSetNftType(index, isERC1155 ? 1 : 0)
}

export const useNftType = (
  index: number,
  address: `0x${string}`,
  setDiscountParams: Dispatch<SetStateAction<DiscountParams>>
) => {
  const [nftType, setNftType] = useState<NFTStringType>("")

  useEffect(() => {
    update(index, address, setNftType, setDiscountParams)
  }, [address])

  return nftType
}

import { productsModuleAddress } from "@lib/initProvider"
import { NewTransaction } from "@rainbow-me/rainbowkit/dist/transactions/transactionStore"
import { prepareWriteContract } from "@wagmi/core"
import { BigNumber } from "ethers"
import { writeContract } from "@wagmi/core"
import { erc20ABI } from "wagmi"

const SetERC20Approve = async (
  address: string,
  amount: BigNumber,
  addRecentTransaction: (transaction: NewTransaction) => void
) => {
  try {
    const { request } = await prepareWriteContract({
      address,
      abi: erc20ABI,
      functionName: "approve",
      args: [productsModuleAddress, BigInt(amount.toHexString())]
    })
    const { hash } = await writeContract(request)
    addRecentTransaction({
      hash,
      description: "Approve USDC"
    })
    return hash
  } catch (err) {
    throw err
  }
}

export default SetERC20Approve

import { useAddRecentTransaction } from "@rainbow-me/rainbowkit"
import formatNumber from "@utils/formatNumber"
import { Message } from "@utils/handleMessage"
import { BigNumber, ethers } from "ethers"
import { LogDescription } from "ethers/lib/utils"
import { AddressAmount } from "pages/slicer/[id]"
import { Dispatch, SetStateAction, useState } from "react"
import ResolvedAddress from "../ResolvedAddress"
import { useContractWrite, usePrepareContractWrite } from "wagmi"
import Slicer from "artifacts/contracts/Slicer.sol/Slicer.json"
import executeTransaction from "@utils/executeTransaction"
import { useAppContext } from "../context"
import { useConnect } from "@walletkit/wagmi-v1-link"

type Props = {
  index: number
  slicerAddress: string
  slicerId: number
  totalSlices: number
  owner: AddressAmount
  unreleasedOwner: bigint
  unreleased: number[]
  setUnreleased: Dispatch<SetStateAction<number[]>>
}

const OwnerBlock = ({
  index,
  slicerAddress,
  slicerId,
  totalSlices,
  owner,
  unreleasedOwner,
  unreleased,
  setUnreleased
}: Props) => {
  const { account } = useAppContext()
  const addRecentTransaction = useAddRecentTransaction()
  const { connect: connectWalletKit } = useConnect()

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState<Message>({
    message: "",
    messageStatus: "success"
  })
  const [success, setSuccess] = useState(false)
  const [logs, setLogs] = useState<LogDescription[]>()

  const unreleasedAmount =
    unreleasedOwner && unreleasedOwner != BigInt(0)
      ? Number(unreleasedOwner / BigInt(10 ** 16)) / 100
      : null

  const { config } = usePrepareContractWrite({
    address: slicerAddress,
    abi: Slicer.abi,
    functionName: "release",
    args: [owner.address, ethers.constants.AddressZero, true]
  })
  const { writeAsync } = useContractWrite(config)

  const submit = async () => {
    await executeTransaction(
      writeAsync,
      setLoading,
      `Withdraw ${unreleasedAmount} ETH | Slicer #${slicerId}`,
      addRecentTransaction,
      () => {
        setSuccess(true)
        const newUnreleased = [...unreleased]
        newUnreleased[index] = 0
        setUnreleased(newUnreleased)
      }
    )
  }

  return (
    <div className="flex items-center justify-between">
      <p className="truncate">
        <ResolvedAddress address={owner.address} />
      </p>
      <div className="text-right min-w-[150px]">
        <p>
          <b className="pr-1">{formatNumber(owner.amount)}</b>{" "}
          <span className="text-sm">
            ({((owner.amount / totalSlices) * 100).toFixed(1)}%)
          </span>
        </p>

        {/* {!success ? (
          unreleasedAmount ? (
            <p className="text-sm font-medium text-gray-400">
              {!loading ? (
                <a onClick={account ? submit : connectWalletKit}>
                  Release{" "}
                  {unreleasedAmount == 0 && unreleasedOwner != BigInt(0)
                    ? "~0"
                    : unreleasedAmount}{" "}
                  Ξ
                </a>
              ) : (
                "Releasing..."
              )}
            </p>
          ) : null
        ) : (
          <p className="text-sm font-medium text-gray-400">
            Released {unreleasedAmount} Ξ! 🎉
          </p>
        )} */}
      </div>
    </div>
  )
}

export default OwnerBlock

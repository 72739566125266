import Wallet from "@components/icons/Wallet"
import Logo from "@components/icons/Logo"
import ShoppingBag from "@components/icons/ShoppingBag"
import { Dispatch, SetStateAction } from "react"
import { DropdownMenuElement } from ".."
import FileText from "@components/icons/FileText"
import { getSliceSubdomain } from "@utils/getSliceSubdomain"
import Package from "@components/icons/Package"
import { useAppContext } from "../context"
import { useConnect, useSIWE } from "@walletkit/wagmi-v1-link"
import useQuery from "@utils/subgraphQuery"
import Store from "@components/icons/Store"
type Props = {
  setShowDropdown: Dispatch<SetStateAction<boolean>>
  ownedSlicers?: any[]
}

function DropdownMenu({ setShowDropdown, ownedSlicers }: Props) {
  const { account, setModalView } = useAppContext()
  const { connect: connectWalletkit } = useConnect()
  const { address: senderWallet } = useSIWE()

  const slicerId = Number(ownedSlicers?.[0]?.slicer?.id)

  return (
    <div
      className={`z-20 absolute top-0 right-0 w-64 sm:w-56 p-1.5 mt-20 border border-opacity-80 border-gray-200 space-y-1 bg-white rounded-lg shadow-base transition-opacity duration-200 nightwind-prevent-block`}
    >
      {ownedSlicers?.length > 0 && (
        <DropdownMenuElement
          href={`${process.env.NEXT_PUBLIC_DASHBOARD_APP_URL}/store/${slicerId}/products`}
          image={<Store className="w-5 h-5" />}
          label="Store manager"
          onClick={() => setShowDropdown(false)}
        />
      )}
      <DropdownMenuElement
        href="/profile"
        image={<Logo className="w-5 h-5" />}
        label="Stores"
        onClick={() => setShowDropdown(false)}
      />
      <DropdownMenuElement
        href="/purchases"
        image={<ShoppingBag strokeWidth="1.5" className="w-5 h-5" />}
        label="Purchases"
        onClick={() => setShowDropdown(false)}
      />
      <DropdownMenuElement
        image={<Wallet strokeWidth="1.5" className="w-5 h-5" />}
        label="Top up wallet"
        onClick={() =>
          setModalView({
            name: "TOPUP_VIEW",
            params: {
              accountTopUpAddress: account,
              senderWallet
            },
            cross: true
          })
        }
      />
      {!senderWallet && (
        <div className="xs:hidden">
          <DropdownMenuElement
            image={<Wallet strokeWidth="1.5" className="w-5 h-5" />}
            label="Connect smart wallet"
            onClick={connectWalletkit}
          />
        </div>
      )}
      {/* <DropdownMenuElement
        href="/earnings"
        image={<Banknotes className="w-5 h-5" />}
        label="Earnings"
        onClick={() => setShowDropdown(false)}
      /> */}
      {/* <DropdownMenuElement
        href={`https://${getSliceSubdomain()}redeem.slice.so/`}
        image={
          <div className="w-5">
            <Package />
          </div>
        }
        label="Redeem"
        onClick={() => setShowDropdown(false)}
      />
      <DropdownMenuElement
        href={`https://${getSliceSubdomain()}redeem.slice.so/forms`}
        image={
          <div className="w-5">
            <FileText />
          </div>
        }
        label="Forms"
        onClick={() => setShowDropdown(false)}
      /> */}
      {/* <div className="xs:hidden">
        <DropdownMenuElement
          image={<Nightwind size="h-5" onClick={null} />}
          label="Toggle dark mode"
          onClick={() => toggle()}
        />
      </div> */}
    </div>
  )
}

export default DropdownMenu

import { readContracts } from "wagmi"
import { Abi } from "viem"

const multicall = async (
  to: `0x${string}` | `0x${string}`[],
  abi: Abi,
  functionName: string,
  allArgs: string[][]
) => {
  if (typeof to === "string") {
    return await readContracts({
      contracts: allArgs.map((args) => ({
        address: to,
        abi,
        functionName,
        args
      }))
    })
  } else {
    return await readContracts({
      contracts: to.map((address, i) => ({
        address,
        abi,
        functionName,
        args: allArgs[i]
      }))
    })
  }
}

export default multicall

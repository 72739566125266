import { useState, Dispatch, SetStateAction } from "react"
import {
  Button,
  NoteText,
  SliceConnectButton,
  SliceFormBlockSplitter
} from "@components/ui"
import { Message } from "@utils/handleMessage"
import { LogDescription } from "ethers/lib/utils"
import MessageBlock from "../MessageBlock"
import { useAppContext } from "../context"
import getLog from "@utils/getLog"
import { ethers } from "ethers"
import PieChart from "../PieChart"
import SliceFormDescription from "../SliceFormDescription"
import { useAddRecentTransaction } from "@rainbow-me/rainbowkit"
import { track } from "@vercel/analytics"
import { useEthersSigner } from "@utils/ethers"
import constants from "constants.json"
import { useContractWrite, usePrepareContractWrite } from "wagmi"
import SliceCore from "artifacts/contracts/SliceCore.sol/SliceCore.json"
import executeTransaction from "@utils/executeTransaction"
import { formatFlags } from "@utils/formatFlags"
import { usdc } from "../WithdrawButton/WithdrawButton"

type Props = {
  success: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  setSuccess: Dispatch<SetStateAction<boolean>>
  setLogs: Dispatch<SetStateAction<LogDescription[]>>
}

const SliceForm = ({ success, setLoading, setSuccess, setLogs }: Props) => {
  const { account: creator, isConnected } = useAppContext()
  const addRecentTransaction = useAddRecentTransaction()
  const [addresses, setAddresses] = useState([""])
  const [shares, setShares] = useState([1000000])
  const [superownerThreshold, setSuperownerThreshold] = useState(51)
  const [totalShares, setTotalShares] = useState(1000000)
  const [isImmutable, setIsImmutable] = useState(false)
  const [isCreatorMetadata, setIsCreatorMetadata] = useState(false)
  const [message, setMessage] = useState<Message>({
    message: "",
    messageStatus: "success"
  })
  const [loadingButton, setLoadingButton] = useState(false)
  const signer = useEthersSigner({
    chainId: Number(process.env.NEXT_PUBLIC_CHAIN_ID)
  })

  const cleanedAddresses = addresses?.filter((el) => el != "")
  const cleanedShares = shares?.filter((el) => el != 0)
  const payees = cleanedAddresses?.map((account, i) => ({
    account,
    shares: cleanedShares[i],
    transfersAllowedWhileLocked: false
  }))
  const minimumShares = Math.ceil((superownerThreshold * totalShares) / 100)
  const slicerFlags = formatFlags([
    isImmutable
    // controller.currenciesControlled,
    // controller.productsControlled,
    // acceptsAllCurrencies,
  ])
  // const sliceCoreFlags = formatFlags([
  //   isCustomRoyaltyActive,
  //   isRoyaltyReceiverSlicer,
  //   isResliceAllowed,
  //   isControlledTransferAllowed,
  // ])

  const { config } = usePrepareContractWrite({
    address:
      constants[process.env.NEXT_PUBLIC_CHAIN_ID][
        process.env.NEXT_PUBLIC_ENVIRONMENT
      ].addresses.SliceCore,
    abi: SliceCore.abi,
    functionName: "slice",
    args: [
      {
        payees,
        minimumShares,
        currencies: [usdc],
        releaseTimelock: 0,
        transferTimelock: 0,
        controller: ethers.constants.AddressZero,
        slicerFlags,
        sliceCoreFlags: 0
      }
    ]
  })
  const { writeAsync } = useContractWrite(config)

  // const allowedSuperOwners =
  //   totalShares / minimumShares > 1000
  //     ? `${formatNumber(totalShares / minimumShares)}`
  //     : `${totalShares / minimumShares}`.split(".")[0]

  const submit = async (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()

    // track("create_slicer_attempt")
    const fetcher = (await import("@utils/fetcher")).default
    const handleMessage = (await import("@utils/handleMessage")).default
    const launchConfetti = (await import("@utils/launchConfetti")).default
    const handleLog = (await import("@utils/handleLog")).default

    const baseUrl = process.env.NEXT_PUBLIC_APP_URL

    setLoadingButton(true)

    try {
      if (cleanedShares.length == cleanedAddresses.length) {
        const waitData = await executeTransaction(
          writeAsync,
          setLoadingButton,
          "Create store",
          addRecentTransaction
        )
        if (!waitData) throw "Transaction failed"
        setLoading(true)

        const { sliceCore } = await import("@lib/initProvider")
        const contract = sliceCore(signer)

        const eventLogs = await handleLog(contract, waitData)
        const eventLog = getLog(eventLogs, "TokenSliced")
        const slicerId = eventLog?.tokenId
        const slicerAddress = eventLog?.slicerAddress

        const body = {
          body: JSON.stringify({
            slicerAddress,
            isImmutable,
            isCreatorMetadata,
            totalShares,
            minimumShares,
            creator
          }),
          method: "POST"
        }
        await fetcher(`${baseUrl}/api/slicer/${Number(slicerId)}/create`, body)
        setLogs(eventLogs)
        launchConfetti()
        // track("create_slicer_success")
        setSuccess(true)
      } else {
        handleMessage(
          {
            message: "Inputs don't correspond, please try again",
            messageStatus: "error"
          },
          setMessage
        )
      }
    } catch (err) {
      // track("create_slicer_fail")
      console.log(err)
    }

    setLoading(false)
    setLoadingButton(false)
  }

  return (
    <form className="md:flex" onSubmit={submit}>
      <div className="w-full max-w-screen-sm py-6 mx-auto space-y-4 md:flex-grow md:w-3/5">
        <SliceFormDescription />
        <SliceFormBlockSplitter
          success={success}
          addresses={addresses}
          shares={shares}
          superownerThreshold={superownerThreshold}
          totalShares={totalShares}
          isImmutable={isImmutable}
          isCreatorMetadata={isCreatorMetadata}
          setAddresses={setAddresses}
          setShares={setShares}
          setSuperownerThreshold={setSuperownerThreshold}
          setTotalShares={setTotalShares}
          setIsImmutable={setIsImmutable}
          setIsCreatorMetadata={setIsCreatorMetadata}
        />
        <div className="py-8 space-y-4 text-yellow-600 sm:px-3">
          {totalShares > 4000000000 && (
            <NoteText
              error
              text="Slicers can have up to 4 Billion slices in total"
            />
          )}
          {totalShares === 1 && (
            <NoteText text="you are about to create a non-fractionalized Slicer. That means that there can only be a single owner at any given time" />
          )}
          {/* {minimumShares != 0 && totalShares == minimumShares && (
            <NoteText text="Only someone who holds all of the slices can add products or change the metadata of this slicer. Superowner slices cannot be changed later, so make sure this is the desired behaviour" />
          )} */}
          <NoteText
            text="Total slices and superowner threshold cannot be
              changed later"
          />
          {process.env.NEXT_PUBLIC_CHAIN_ID === "5" && (
            <NoteText
              text={
                <>
                  You can get Goerli ETH from the{" "}
                  <a
                    href="https://faucet.paradigm.xyz/"
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    Paradigm
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://goerlifaucet.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    Alchemy
                  </a>{" "}
                  faucets
                </>
              }
            />
          )}
        </div>
        <div>
          <MessageBlock msg={message} />
        </div>
      </div>
      <div className="justify-center md:h-[550px] md:flex md:flex-col md:w-2/5 md:sticky md:top-0 md:-mt-12">
        <div>
          <PieChart
            addresses={addresses}
            shares={shares}
            minimumShares={minimumShares}
            totalShares={totalShares}
          />
          {/* <div className="pt-8 mx-auto">
            <div className="space-y-3 text-center">
              <div>
                <p className="pb-1">Owners: {shares.filter((n) => n).length}</p>
              </div>
              <div>
                <p className="pb-1">
                  Superowners:{" "}
                  {minimumShares && minimumShares > 0 ? (
                    <>
                      {shares.filter((n) => n >= minimumShares).length} /{" "}
                      {allowedSuperOwners}
                    </>
                  ) : (
                    "0 / 0"
                  )}
                </p>
              </div>
            </div>
          </div> */}
          <div className="flex justify-center py-12 md:pt-12 md:pb-0">
            {!isConnected ? (
              <SliceConnectButton size="large" />
            ) : (
              <Button
                label="Create store"
                type="submit"
                loading={loadingButton}
              />
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

export default SliceForm

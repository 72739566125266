import Chevron from "@components/icons/Chevron"
import ShoppingBag from "@components/icons/ShoppingBag"
import Spinner from "@components/icons/Spinner"
import { ProductCart } from "@lib/handleUpdateCart"
import { useState } from "react"
import { CartList } from ".."
import { useAppContext } from "../context"
import { BigNumber, ethers, utils } from "ethers"
import { useAddRecentTransaction } from "@rainbow-me/rainbowkit"
import { track } from "@vercel/analytics"
import { getExternalPrices } from "@lib/useExternalPrices"
import useEthUsd from "@utils/useEthUsd"
import launchConfetti from "@utils/launchConfetti"
import { updatePurchases } from "@utils/getPurchases"
import { sendMessage } from "@utils/xmtp"
import { GetERC20Allowance, SetERC20Approve } from "@lib/handlers/chain"
import { useRouter } from "next/router"
import { _getAccountBalance } from "@utils/getAccountBalance"
import { usdc } from "../WithdrawButton/WithdrawButton"
import { waitForTransaction } from "@wagmi/core"
import {
  payProducts,
  payProductsConfig
} from "@lib/handlers/chain/PayProducts/PayProducts"
import { formatEther } from "viem"
import {
  useConnect,
  useSIWE,
  useBatchWriteContract
} from "@walletkit/wagmi-v1-link"
import { useAccount, usePublicClient } from "wagmi"
import { erc20ABI } from "wagmi"
import { productsModuleAddress } from "@lib/initProvider"

export const appChainId = Number(process.env.NEXT_PUBLIC_CHAIN_ID)
export const bridgeChainId = appChainId === 8453 ? 1 : 5

const FloatingCart = () => {
  const {
    setPurchases,
    cart,
    updateCart,
    purchases,
    modalView,
    setModalView,
    account
  } = useAppContext()
  const publicClient = usePublicClient()
  const router = useRouter()
  const addRecentTransaction = useAddRecentTransaction()
  const { cart: cartQuery } = router.query
  const [showCartList, setShowCartList] = useState(!!cartQuery)
  const [loading, setLoading] = useState(false)
  const [loadingState, setLoadingState] = useState("")
  const [errorState, setErrorState] = useState("")
  const ethUsd = useEthUsd()
  const showCart = cart?.length != 0
  const { connect: connectWalletKit } = useConnect()
  const { address: signerWallet } = useSIWE()
  const { connector } = useAccount()
  const {batchWriteContractAsync} = useBatchWriteContract();

  const reducerEth = (previousValue: number, currentValue: ProductCart) => {
    const { price, isUSD, extCallValue, currency, extRelativePrice } =
      currentValue
    const productPriceEth = isUSD
      ? Math.floor(Number(price) / (ethUsd * 100)) / 10000
      : currency === ethers.constants.AddressZero &&
        Math.floor(Number(price) / 10 ** 14) / 10000

    const externalCallEth = !extRelativePrice
      ? utils.formatEther(extCallValue)
      : 0

    return previousValue + Number(productPriceEth) + Number(externalCallEth)
  }
  const totalPriceEth: number = cart?.reduce(reducerEth, 0) || 0

  const reducerUsdc = (previousValue: number, currentValue: ProductCart) => {
    const { price, currency } = currentValue
    const productPriceUsdc =
      currency != ethers.constants.AddressZero ? Number(price) : 0

    return previousValue + Number(productPriceUsdc)
  }
  let totalPriceUsdc: number = cart?.reduce(reducerUsdc, 0) || 0

  const isSmartWallet = connector?.id === "walletKitLink"

  const handleCheckout = async () => {
    try {
      // track("checkout_cart_attempt")
      setLoading(true)
      const updatedCart = [...cart]
      const buyer = signerWallet || account

      const dynamicItems = cart.filter(
        (el) => el.externalAddress != ethers.constants.AddressZero
      )

      if (dynamicItems.length != 0) {
        setLoadingState("Updating prices")
        const ids: [number, number][] = []
        const args = []
        const currencies = []

        dynamicItems.forEach((el) => {
          const { slicerId, productId, quantity, currency } = el
          ids.push([slicerId, productId])
          args.push([slicerId, productId, currency, quantity, buyer, ""])
          currencies.push(currency)
        })

        const dynamicPrices = await getExternalPrices(args, ids, currencies)

        Object.entries(dynamicPrices).forEach(([slicerId, productVal]) => {
          Object.entries(productVal).forEach(([productId, currencyVal]) => {
            const currency = dynamicItems.find(
              (el) =>
                el.slicerId == Number(slicerId) &&
                el.productId == Number(productId)
            ).currency
            const price =
              currencyVal[currency][
                currency === ethers.constants.AddressZero
                  ? "ethPrice"
                  : "currencyPrice"
              ]
            const index = updatedCart.findIndex(
              (el) =>
                el.slicerId == Number(slicerId) &&
                el.productId == Number(productId)
            )

            updatedCart[index].price = "0x" + BigInt(price).toString(16)
          })
        })

        updateCart(updatedCart)
        totalPriceUsdc = updatedCart?.reduce(reducerUsdc, 0) || 0
      }

      const {
        ethBalance,
        usdcBalance,
        ethSenderBalance,
        usdcSenderBalance,
        ethSenderBridge
      } = await _getAccountBalance(account, signerWallet, bridgeChainId)

      if (
        Number(formatEther(ethBalance)) < totalPriceEth ||
        usdcBalance < BigInt(totalPriceUsdc)
      ) {
        if (modalView?.name) {
          setModalView({
            name: ""
          })
        }
        setTimeout(() => {
          setModalView(
            {
              name: "TOPUP_VIEW",
              cross: true,
              params: {
                ethBalance,
                usdcBalance,
                ethSenderBalance,
                usdcSenderBalance,
                ethSenderBridge,
                totalPriceEth,
                totalPriceUsdc,
                accountTopUpAddress: account,
                senderWallet: signerWallet
              }
            },
            0
          )
        })
      } else {
        try {
          const isUsdcProductPresent = updatedCart.some(
            (el) => el.currency != ethers.constants.AddressZero
          )

          const calls = []

          if (isUsdcProductPresent) {
            const allowance = await GetERC20Allowance(
              usdc as `0x${string}`,
              account
            )
            const allowanceRequired = BigNumber.from(totalPriceUsdc)

            if (allowance < BigInt(allowanceRequired.toHexString())) {
              if (isSmartWallet) {
                calls.push({
                  address: usdc as `0x${string}`,
                  abi: erc20ABI,
                  functionName: "approve",
                  args: [
                    productsModuleAddress,
                    BigInt(allowanceRequired.toHexString())
                  ]
                })
              } else {
                setLoadingState("Approve USDC")

                const hash = await SetERC20Approve(
                  usdc as `0x${string}`,
                  allowanceRequired,
                  addRecentTransaction
                )

                setLoadingState("Approving ...")

                await waitForTransaction({ hash })
              }
            }
          }

          setLoadingState("Confirm purchase")

          let hash: `0x${string}`
          if (isSmartWallet && calls.length) {
            calls.push(
              await payProductsConfig(publicClient, buyer, updatedCart, account)
            )

            const txHash = await batchWriteContractAsync(calls)

            hash = txHash as `0x${string}`

            addRecentTransaction({
              hash,
              description: "Approve USDC and Checkout"
            })
          } else {
            hash = await payProducts(
              publicClient,
              buyer,
              updatedCart,
              addRecentTransaction,
              account
            )
          }

          setLoadingState("Purchasing ...")

          const isBaseChain = ["8453", "84531"].includes(
            process.env.NEXT_PUBLIC_CHAIN_ID
          )

          // Send message on Base Chain
          if (isBaseChain) {
            sendMessage(cart, account)
          }

          await waitForTransaction({ hash })

          const newPurchases = updatePurchases(cart, purchases)
          setPurchases(newPurchases)
          updateCart([])
          launchConfetti()
          // track("checkout_cart_success")

          setModalView({
            name: ""
          })
          setTimeout(() => {
            setModalView({
              name: "CONFIRM_PURCHASE_VIEW",
              cross: true,
              params: {
                cartItems: updatedCart.sort(
                  (obj1, obj2) =>
                    obj1.slicerId - obj2.slicerId ||
                    obj1.productId - obj2.productId
                )
              }
            })
          }, 0)
        } catch (err) {
          console.log(err)

          if (!err.message.includes("User rejected the request")) {
            if (err.message.includes("insufficient funds")) {
              setErrorState("Insufficient funds")
            } else {
              setErrorState("Transaction error")
            }
            setTimeout(() => {
              setErrorState("")
            }, 2000)
          }
        }
      }
    } catch (err) {
      console.log(err)
      // track("checkout_cart_fail")
    }

    setLoading(false)
    setLoadingState("")
  }

  return (
    <>
      <div id="floatingCart">
        <div className="fixed bottom-0 mb-[80px] z-50 sm:mb-[100px] right-[20px] sm:right-[32px]">
          <div className="transition-opacity duration-200"></div>
          {showCartList && (
            <CartList cart={cart} updateCart={updateCart} ethUsd={ethUsd} />
          )}
        </div>
        {(showCart || loading) && (
          <div
            className={`fixed z-50 bottom-0 mb-[20px] sm:mb-[32px] right-[20px] sm:right-[32px] nightwind-prevent-block transition-opacity duration-200`}
          >
            <div className="flex h-12 pl-3 overflow-hidden font-medium text-black bg-white border-2 border-transparent rounded-full shadow-base">
              <div
                className="flex items-center pl-2 pr-4 cursor-pointer group"
                onClick={() => setShowCartList((showCartList) => !showCartList)}
              >
                <Chevron
                  className={`h-5 transition-transform duration-200 ${
                    showCartList ? "rotate-90" : ""
                  } w-7`}
                />
                <p className="w-full ml-2 text-center">
                  {totalPriceEth > 0 &&
                    `Ξ ${Math.round(totalPriceEth * 1000) / 1000}`}
                  {totalPriceEth > 0 && totalPriceUsdc > 0 && " + "}
                  {totalPriceUsdc > 0 &&
                    `USDC ${
                      Math.round(
                        (Math.floor(Number(totalPriceUsdc) / 10 ** 4) / 100) *
                          10
                      ) / 10
                    }`}
                  {totalPriceEth === 0 && totalPriceUsdc === 0 && "Free"}
                </p>
              </div>
              <div
                className={`flex items-center cursor-pointer h-full min-w-[80px] px-6 text-sm text-white ${
                  !loading && !errorState ? "hover:bg-green-500" : ""
                } ${
                  errorState ? "bg-red-500" : "bg-blue-600"
                } nightwind-prevent`}
                onClick={
                  account
                    ? !loading && cart.length
                      ? () => handleCheckout()
                      : null
                    : connectWalletKit
                }
              >
                {loading ? (
                  <div className="flex items-center justify-center w-full">
                    {loadingState && (
                      <p className="pr-4 text-sm">{loadingState}</p>
                    )}
                    <Spinner />
                  </div>
                ) : errorState ? (
                  <p className="pr-2 text-sm">{errorState}</p>
                ) : (
                  <>
                    <p className="pr-2 text-sm ">Checkout</p>
                    <ShoppingBag className="w-[18px] h-[18px]" />
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default FloatingCart

import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { fetchBalance } from "@wagmi/core"
import constants from "constants.json"

type AccountBalance = {
  ethBalance: bigint
  usdcBalance: bigint
  ethSenderBalance: bigint
  usdcSenderBalance: bigint
  ethSenderBridge: bigint
}

const chainId = Number(process.env.NEXT_PUBLIC_CHAIN_ID)

export const getAccountBalance = async (
  account: `0x${string}`,
  sender: `0x${string}`,
  setAccountBalance: Dispatch<SetStateAction<AccountBalance>>,
  bridgeChainId: number
) => {
  if (!sender) return
  const currentAccountBalance = await _getAccountBalance(
    account,
    sender,
    bridgeChainId
  )

  setAccountBalance(currentAccountBalance)
}

export const useAccountBalance = (
  account: `0x${string}`,
  sender: `0x${string}`,
  initAccountBalance?: AccountBalance,
  bridgeChainId?: number
) => {
  const [accountBalance, setAccountBalance] =
    useState<AccountBalance>(initAccountBalance)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const clearAndSetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
    intervalRef.current = setInterval(() => {
      if (account) {
        getAccountBalance(account, sender, setAccountBalance, bridgeChainId)
      }
    }, 15000)
  }

  const mutate = () => {
    getAccountBalance(account, sender, setAccountBalance, bridgeChainId)
    clearAndSetInterval()
  }

  useEffect(() => {
    if (Object.values(initAccountBalance).some((v) => v === undefined)) {
      getAccountBalance(account, sender, setAccountBalance, bridgeChainId)
    }

    clearAndSetInterval()

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [account, sender])

  return { data: accountBalance, mutate }
}

export const _getAccountBalance = async (
  account: `0x${string}`,
  sender: `0x${string}`,
  bridgeChainId: number
) => {
  const [
    ethBalance,
    usdcBalance,
    ethSenderBalance,
    usdcSenderBalance,
    ethSenderBalanceBridge
  ] = await Promise.all([
    fetchBalance({
      address: account,
      chainId
    }),
    fetchBalance({
      address: account,
      token:
        constants[process.env.NEXT_PUBLIC_CHAIN_ID][
          process.env.NEXT_PUBLIC_ENVIRONMENT
        ].addresses["USDCPriceFeed"],
      chainId
    }),
    sender &&
      sender != account &&
      fetchBalance({
        address: sender,
        chainId
      }),
    sender &&
      sender != account &&
      fetchBalance({
        address: sender,
        token:
          constants[process.env.NEXT_PUBLIC_CHAIN_ID][
            process.env.NEXT_PUBLIC_ENVIRONMENT
          ].addresses["USDCPriceFeed"],
        chainId
      }),
    sender &&
      fetchBalance({
        address: sender,
        chainId: bridgeChainId
      })
  ])

  return {
    ethBalance: ethBalance?.value,
    usdcBalance: usdcBalance?.value,
    ethSenderBalance: ethSenderBalance?.value,
    usdcSenderBalance: usdcSenderBalance?.value,
    ethSenderBridge: ethSenderBalanceBridge?.value
  }
}

import { productsModuleAddress } from "@lib/initProvider"
import { NewTransaction } from "@rainbow-me/rainbowkit/dist/transactions/transactionStore"
import { prepareWriteContract } from "@wagmi/core"
import { writeContract } from "@wagmi/core"
import ProductsModule from "artifacts/contracts/ProductsModule.sol/ProductsModule.json"

const RemoveProduct = async (
  slicerId: number,
  productId: number,
  addRecentTransaction: (transaction: NewTransaction) => void
) => {
  try {
    const { request } = await prepareWriteContract({
      address: productsModuleAddress,
      abi: ProductsModule.abi,
      functionName: "removeProduct",
      args: [slicerId, productId]
    })
    const { hash } = await writeContract(request)
    addRecentTransaction({
      hash,
      description: `Remove product ${productId} | Slicer #${slicerId}`
    })
  } catch (err) {
    throw err
  }
}

export default RemoveProduct

import { erc20ABI, readContract } from "@wagmi/core"
import { productsModuleAddress } from "@lib/initProvider"

const GetERC20Allowance = async (
  address: `0x${string}`,
  buyer: `0x${string}`
) => {
  try {
    return await readContract({
      address,
      abi: erc20ABI,
      functionName: "allowance",
      args: [buyer, productsModuleAddress]
    })
  } catch (err) {
    throw err
  }
}

export default GetERC20Allowance

import {
  BrandedButton,
  Button,
  CopyAddress,
  DoubleText,
  Input,
  PayWithCoinbaseButton
} from "@components/ui"
import { useAppContext } from "@components/ui/context"
import { useState } from "react"
import Spinner from "@components/icons/Spinner"
import { useAccount, erc20ABI, useConnect } from "wagmi"
import { sendTransaction, writeContract, switchNetwork } from "@wagmi/core"
import { formatEther, parseAbi, parseEther } from "viem"
import CoinbaseWalletLogo from "@components/icons/CoinbaseWalletLogo"
import UniswapLogo from "@components/icons/UniswapLogo"
import Image from "next/image"
import baseInstructionsImg from "public/baseInstructions.png"
import baseInstructionsImg1 from "public/baseInstructions1.png"
import Chevron from "@components/icons/Chevron"
import { usdc } from "@components/ui/WithdrawButton/WithdrawButton"
import { useAccountBalance } from "@utils/getAccountBalance"
import executeTransaction from "@utils/executeTransaction"
import { useAddRecentTransaction } from "@rainbow-me/rainbowkit"
import { BaseBridgeAddress, USDCPriceFeedAddress } from "@lib/initProvider"
import Check from "@components/icons/Check"
import {
  appChainId,
  bridgeChainId
} from "@components/ui/FloatingCart/FloatingCart"
import { useSIWE } from "@walletkit/wagmi-v1-link"
import shortenAddress from "@utils/shortenAddress"

export const TOPUP_VIEW = (params: any) => {
  const {
    ethBalance,
    usdcBalance,
    ethSenderBalance,
    usdcSenderBalance,
    ethSenderBridge,
    totalPriceEth,
    totalPriceUsdc,
    accountTopUpAddress,
    senderWallet
  } = params
  const { setModalView } = useAppContext()
  const addRecentTransaction = useAddRecentTransaction()
  const isCheckout = totalPriceEth || totalPriceUsdc
  const { address: signerWallet, connector: signerConnector } = useSIWE()
  const { address: account, connector: activeConnector } = useAccount()
  const { connectAsync } = useConnect()

  const [isCollapsed, setIsCollapsed] = useState(true)

  const isCoinbaseWallet =
    (activeConnector || signerConnector)?.id == "coinbaseWallet"
  const { data: currentBalance, mutate } = useAccountBalance(
    accountTopUpAddress,
    signerWallet || account,
    {
      ethBalance,
      usdcBalance,
      ethSenderBalance,
      usdcSenderBalance,
      ethSenderBridge
    },
    bridgeChainId
  )

  const formattedTotalPriceEth = totalPriceEth
    ? parseEther(String(totalPriceEth))
    : BigInt(0)
  const missingEth = (currentBalance.ethBalance || 0) < formattedTotalPriceEth
  const missingUsdc = (currentBalance.usdcBalance || 0) < totalPriceUsdc

  const ethTopUpAmount =
    formattedTotalPriceEth - BigInt(currentBalance.ethBalance || 0)
  const usdcTopUpAmount = Number(
    (totalPriceUsdc || 0) - Number(currentBalance.usdcBalance || 0)
  )

  const formattedUsdcTopUpAmount = Math.ceil(usdcTopUpAmount / 10 ** 6)
  const formattedEthTopUpAmount =
    Math.ceil(Number(formatEther(ethTopUpAmount)) * 1000) / 1000

  const getMinUsdcTopUpAmount = () => {
    return Math.min(
      formattedUsdcTopUpAmount,
      currentBalance?.usdcSenderBalance != undefined
        ? Math.floor(Number(currentBalance?.usdcSenderBalance) / 10 ** 6)
        : 0
    )
  }
  const [inputSendUsdcTopUpAmount, setInputSendUsdcTopUpAmount] = useState(
    getMinUsdcTopUpAmount()
  )
  const [isSendUsdcLoading, setIsSendUsdcLoading] = useState(false)
  const [isSendUsdcSuccess, setIsSendUsdcSuccess] = useState(false)

  const getMinEthTopUpAmount = (ethAmount: bigint | undefined) => {
    return Math.min(
      formattedEthTopUpAmount,
      ethAmount != undefined
        ? Math.floor(Number(formatEther(ethAmount)) * 1000) / 1000
        : 0
    )
  }
  const [inputSendEthTopUpAmount, setInputSendEthTopUpAmount] = useState(
    getMinEthTopUpAmount(currentBalance?.ethSenderBalance)
  )
  const [isSendEthLoading, setIsSendEthLoading] = useState(false)
  const [isSendEthSuccess, setIsSendEthSuccess] = useState(false)

  const [inputBridgeEthTopUpAmount, setInputBridgeEthTopUpAmount] = useState(
    getMinEthTopUpAmount(currentBalance?.ethSenderBridge)
  )
  const [isBridgeEthLoading, setIsBridgeEthLoading] = useState(false)
  const [isBridgeEthSuccess, setIsBridgeEthSuccess] = useState(false)

  return (
    <>
      <div className="pb-3 text-center">
        <DoubleText inactive logoText="Top up wallet" />
      </div>
      {!isCheckout || missingEth || missingUsdc ? (
        <>
          <div className="pb-4 text-center ">
            <div className="pl-4">
              <CopyAddress slicerAddress={accountTopUpAddress} />
            </div>
            {isCheckout && (
              <p className="pt-6 text-gray-600 mx-auto max-w-[460px]">
                Your wallet doesn&apos;t have enough funds on Base to checkout.
                Use these options or send funds (on Base) directly to the
                address above.
              </p>
            )}
          </div>
          <div className="pt-4 space-y-12">
            {isCheckout && !missingUsdc ? null : (
              <div className="space-y-6">
                <div className="mb-5 mx-auto max-w-[320px]">
                  <div className="flex justify-between">
                    <h2 className="text-2xl">USDC</h2>
                    <p className="text-gray-600 text-sm self-end mb-0.5">
                      Balance:{" "}
                      {currentBalance.usdcBalance != undefined ? (
                        <strong>
                          {Math.floor(
                            Number(currentBalance.usdcBalance) / 10 ** 6
                          )}{" "}
                          USDC
                        </strong>
                      ) : (
                        <Spinner className="inline-block w-4 h-4" />
                      )}
                    </p>
                  </div>
                  {isCheckout && (
                    <p className="mt-1 text-sm text-gray-600">
                      {missingUsdc ? (
                        <>
                          Top up at least{" "}
                          <strong>{formattedUsdcTopUpAmount} USDC</strong>
                        </>
                      ) : (
                        <span className="flex items-center gap-1 text-green-600">
                          No need to top up <Check className="w-5 h-5" />
                        </span>
                      )}
                    </p>
                  )}
                </div>

                <div className="text-center space-y-7">
                  {senderWallet && (
                    <div className="w-[320px] mx-auto mb-8">
                      {!isSendUsdcSuccess ? (
                        <Input
                          label={
                            <>
                              Send from your wallet{" "}
                              <span className="text-sm font-normal">
                                ({shortenAddress(senderWallet, "...")})
                              </span>
                            </>
                          }
                          labelSize="text-base"
                          helpText={
                            <>
                              Up to{" "}
                              {currentBalance?.usdcSenderBalance !=
                              undefined ? (
                                `${Math.floor(
                                  Number(currentBalance?.usdcSenderBalance) /
                                    10 ** 6
                                )} USDC`
                              ) : (
                                <Spinner className="inline-block w-4 h-4 ml-1" />
                              )}
                            </>
                          }
                          value={inputSendUsdcTopUpAmount}
                          onChange={setInputSendUsdcTopUpAmount}
                          type="number"
                          min="0"
                          step="1"
                          max={
                            currentBalance?.usdcSenderBalance &&
                            Math.floor(
                              Number(currentBalance?.usdcSenderBalance) /
                                10 ** 6
                            )
                          }
                          loading={isSendUsdcLoading}
                          onClick={async () => {
                            setIsSendUsdcLoading(true)

                            const smartWalletConnector = activeConnector
                            await connectAsync({ connector: signerConnector })

                            try {
                              if (
                                (await signerConnector.getChainId()) !=
                                appChainId
                              ) {
                                await switchNetwork({ chainId: appChainId })
                              }
                              await executeTransaction(
                                () =>
                                  writeContract({
                                    address: USDCPriceFeedAddress,
                                    abi: erc20ABI,
                                    functionName: "transfer",
                                    args: [
                                      accountTopUpAddress,
                                      inputSendUsdcTopUpAmount * 10 ** 6
                                    ]
                                  }),
                                undefined,
                                `Fund USDC (Send from wallet)`,
                                addRecentTransaction,
                                () => {
                                  mutate()
                                  setTimeout(() => {
                                    setIsSendUsdcSuccess(true)
                                  }, 500)
                                },
                                undefined
                              )
                            } catch (err) {
                              console.log(err)
                            }
                            await connectAsync({
                              connector: smartWalletConnector
                            })
                            setIsSendUsdcLoading(false)
                          }}
                          onClickLabel="Send"
                          prefix="$"
                          disabled={
                            currentBalance?.usdcSenderBalance == BigInt(0)
                          }
                        />
                      ) : (
                        <div className="text-left text-green-600">
                          <div className="flex items-center gap-1">
                            <p className="font-semibold">
                              USDC Sent successfully
                            </p>
                            <Check className="w-5 h-5" />
                          </div>
                          <p
                            className="inline-block text-sm underline cursor-pointer opacity-70 hover:opacity-100"
                            onClick={() => {
                              setIsSendUsdcSuccess(false)
                              setInputSendUsdcTopUpAmount(
                                getMinUsdcTopUpAmount()
                              )
                            }}
                          >
                            Send more
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  <div>
                    <PayWithCoinbaseButton
                      usdcTopUpAmount={formattedUsdcTopUpAmount}
                      mutate={mutate}
                    />
                  </div>
                  <div>
                    <BrandedButton
                      text="Swap USDC on Uniswap"
                      bgColor="bg-pink-500 text-pink-500"
                      logo={
                        <UniswapLogo className="text-pink-500 transition-colors duration-150 group-hover:text-white nightwind-prevent" />
                      }
                      href={`https://app.uniswap.org/swap?exactField=output&exactAmount=${formattedUsdcTopUpAmount}&chain=base&inputCurrency=ETH&outputCurrency=${usdc}`}
                      external
                    />
                  </div>

                  {isCoinbaseWallet && (
                    <>
                      <div
                        className="group flex items-center justify-between my-2 cursor-pointer mx-auto max-w-[320px]"
                        onClick={() => setIsCollapsed(!isCollapsed)}
                      >
                        <div className="flex items-center gap-2">
                          <Chevron
                            className={`w-6 transition-transform duration-200 ease-out ${
                              isCollapsed
                                ? "group-hover:translate-x-[6px] -rotate-180"
                                : "-rotate-90"
                            }`}
                          />
                          <p className="font-semibold">
                            Swap on Coinbase Wallet
                          </p>
                          <div className="w-6 mr-3">
                            <CoinbaseWalletLogo />
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          isCollapsed
                            ? "hidden"
                            : "block prose mx-auto max-w-[320px] text-left"
                        }
                      >
                        <ol>
                          <li>
                            <p>
                              On Coinbase Wallet, click on the{" "}
                              <strong>Assets</strong> tab at the bottom and then
                              on the <strong>Buy</strong> or{" "}
                              <strong>Swap</strong> buttons.
                            </p>
                          </li>
                          <Image
                            src={baseInstructionsImg}
                            alt="Swap USDC for ETH"
                            width={300}
                            height={300}
                            layout="responsive"
                            className="mx-auto rounded-lg"
                          />
                          <li>
                            <p>
                              To swap, select ETH (or another asset) as input
                              token and <strong>USDC</strong> as the token to{" "}
                              <strong>receive</strong>.
                            </p>
                          </li>
                          <Image
                            src={baseInstructionsImg1}
                            alt="Swap USDC for ETH"
                            width={300}
                            height={300}
                            layout="responsive"
                            className="mx-auto rounded-lg"
                          />
                          <li>
                            <p>When completed, you can checkout again.</p>
                          </li>
                        </ol>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {isCheckout && !missingEth ? null : (
              <div>
                <div className="mb-5 mx-auto max-w-[320px]">
                  <div className="flex justify-between">
                    <h2 className="text-2xl">ETH</h2>
                    <p className="text-gray-600 text-sm self-end mb-0.5">
                      Balance:{" "}
                      {currentBalance.ethBalance != undefined ? (
                        <strong>
                          {Math.floor(
                            Number(formatEther(currentBalance.ethBalance)) *
                              1000
                          ) / 1000}{" "}
                          ETH
                        </strong>
                      ) : (
                        <Spinner className="inline-block w-4 h-4" />
                      )}
                    </p>
                  </div>
                  {isCheckout && (
                    <p className="mt-1 text-sm text-gray-600">
                      {missingEth ? (
                        <>
                          Top up at least{" "}
                          <strong>{formattedEthTopUpAmount} ETH</strong>
                        </>
                      ) : (
                        <span className="flex items-center gap-1 text-green-600">
                          No need to top up <Check className="w-5 h-5" />
                        </span>
                      )}
                    </p>
                  )}
                </div>
                <div className="text-center space-y-7">
                  {senderWallet && (
                    <div className="w-[320px] mx-auto">
                      {!isSendEthSuccess ? (
                        <Input
                          label={
                            <>
                              Send from your wallet{" "}
                              <span className="text-sm font-normal">
                                ({shortenAddress(senderWallet, "...")})
                              </span>
                            </>
                          }
                          labelSize="text-base"
                          helpText={
                            <>
                              Up to{" "}
                              {currentBalance?.ethSenderBalance != undefined ? (
                                `${
                                  Math.floor(
                                    Number(
                                      formatEther(
                                        currentBalance?.ethSenderBalance
                                      )
                                    ) * 1000
                                  ) / 1000
                                } ETH`
                              ) : (
                                <Spinner className="inline-block w-4 h-4 ml-1" />
                              )}
                            </>
                          }
                          value={inputSendEthTopUpAmount}
                          onChange={setInputSendEthTopUpAmount}
                          type="number"
                          min="0"
                          step="0.001"
                          max={
                            currentBalance?.ethSenderBalance &&
                            formatEther(currentBalance?.ethSenderBalance)
                          }
                          loading={isSendEthLoading}
                          onClick={async () => {
                            setIsSendEthLoading(true)
                            const smartWalletConnector = activeConnector
                            await connectAsync({ connector: signerConnector })

                            try {
                              if (
                                (await signerConnector.getChainId()) !=
                                appChainId
                              ) {
                                await switchNetwork({ chainId: appChainId })
                              }
                              await executeTransaction(
                                () =>
                                  sendTransaction({
                                    to: accountTopUpAddress,
                                    value: parseEther(
                                      String(inputSendEthTopUpAmount)
                                    )
                                  }),
                                undefined,
                                `Fund ETH (Send from wallet)`,
                                addRecentTransaction,
                                () => {
                                  mutate()
                                  setTimeout(() => {
                                    setIsSendEthSuccess(true)
                                  }, 500)
                                }
                              )
                            } catch (err) {
                              console.log(err)
                            }
                            await connectAsync({
                              connector: smartWalletConnector
                            })
                            setIsSendEthLoading(false)
                          }}
                          onClickLabel="Send"
                          prefix="Ξ"
                          disabled={
                            currentBalance?.ethSenderBalance == BigInt(0)
                          }
                        />
                      ) : (
                        <div className="text-left text-green-600">
                          <div className="flex items-center gap-1">
                            <p className="font-semibold">
                              ETH Sent successfully
                            </p>
                            <Check className="w-5 h-5" />
                          </div>
                          <p
                            className="inline-block text-sm underline cursor-pointer opacity-70 hover:opacity-100"
                            onClick={() => {
                              setIsSendEthSuccess(false)
                              setInputSendEthTopUpAmount(
                                getMinEthTopUpAmount(
                                  currentBalance?.ethSenderBalance
                                )
                              )
                            }}
                          >
                            Send more
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="w-[320px] mx-auto mb-8">
                    {!isBridgeEthSuccess ? (
                      <Input
                        label={
                          <>
                            Bridge from Ethereum{" "}
                            {appChainId === 8453 ? "Mainnet" : "Goerli"}{" "}
                            {senderWallet && (
                              <span className="text-sm font-normal">
                                ({shortenAddress(senderWallet, "...")})
                              </span>
                            )}
                          </>
                        }
                        labelSize="text-base"
                        helpText={
                          <>
                            Up to{" "}
                            {currentBalance?.ethSenderBridge != undefined ? (
                              `${
                                Math.floor(
                                  Number(
                                    formatEther(currentBalance?.ethSenderBridge)
                                  ) * 1000
                                ) / 1000
                              } ETH`
                            ) : (
                              <Spinner className="inline-block w-4 h-4 ml-1" />
                            )}
                          </>
                        }
                        value={inputBridgeEthTopUpAmount}
                        onChange={setInputBridgeEthTopUpAmount}
                        type="number"
                        min="0"
                        step="0.001"
                        max={
                          currentBalance?.ethSenderBridge &&
                          formatEther(currentBalance?.ethSenderBridge)
                        }
                        loading={isBridgeEthLoading}
                        onClick={async () => {
                          setIsBridgeEthLoading(true)

                          const smartWalletConnector = activeConnector

                          try {
                            if (signerConnector) {
                              await connectAsync({ connector: signerConnector })
                            }

                            if (
                              (await (
                                signerConnector || activeConnector
                              ).getChainId()) != bridgeChainId
                            ) {
                              await switchNetwork({ chainId: bridgeChainId })
                            }

                            await executeTransaction(
                              () =>
                                writeContract({
                                  address: BaseBridgeAddress,
                                  abi: parseAbi([
                                    "function depositTransaction(address,uint256,uint64,bool,bytes)"
                                  ]),
                                  functionName: "depositTransaction",
                                  value: parseEther(
                                    String(inputBridgeEthTopUpAmount)
                                  ),
                                  args: [
                                    accountTopUpAddress,
                                    parseEther(
                                      String(inputBridgeEthTopUpAmount)
                                    ),
                                    100000,
                                    false,
                                    ""
                                  ]
                                }),
                              undefined,
                              undefined,
                              undefined,
                              () => {
                                setIsBridgeEthSuccess(true)
                              }
                            )
                          } catch (error) {
                            console.log(error)
                          }

                          if (
                            (await (
                              signerConnector || activeConnector
                            ).getChainId()) != appChainId
                          ) {
                            try {
                              await switchNetwork({ chainId: appChainId })
                            } catch {}
                          }

                          if (signerConnector) {
                            await connectAsync({
                              connector: smartWalletConnector
                            })
                          }
                          setIsBridgeEthLoading(false)
                        }}
                        onClickLabel="Bridge"
                        prefix="Ξ"
                        disabled={currentBalance?.ethSenderBridge == BigInt(0)}
                      />
                    ) : (
                      <div className="text-left text-green-600">
                        <div className="flex items-center gap-1">
                          <p className="font-semibold">
                            ETH Bridged successfully
                          </p>
                          <Check className="w-5 h-5" />
                        </div>
                        <p className="text-sm">
                          Wait a few minutes for your balance to update
                        </p>
                      </div>
                    )}
                  </div>

                  <div>
                    <BrandedButton
                      text="Swap ETH on Uniswap"
                      bgColor="bg-pink-500 text-pink-500"
                      logo={
                        <UniswapLogo className="text-pink-500 transition-colors duration-150 group-hover:text-white nightwind-prevent" />
                      }
                      href={`https://app.uniswap.org/swap?exactField=output&exactAmount=${formattedEthTopUpAmount}&chain=base&inputCurrency=${usdc}&outputCurrency=ETH`}
                      external
                    />
                  </div>
                  <div>
                    <PayWithCoinbaseButton
                      ethTopUpAmount={formattedEthTopUpAmount}
                      mutate={mutate}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-center pt-8">
            <p
              className="font-medium text-red-500 cursor-pointer hover:underline"
              onClick={() => setModalView({ name: "" })}
            >
              Go back
            </p>
          </div>
        </>
      ) : (
        <div className="space-y-8 text-center">
          <p className="pt-3">You&apos;re good to go!</p>
          <div>
            <Button
              label="Go back to checkout"
              onClick={() => setModalView({ name: "" })}
            />
            <p className="pt-4 text-sm text-gray-600">
              Top up anytime from the top-right menu
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default TOPUP_VIEW

import { createContext, useContext, useEffect, useState } from "react"
import { colorList, darkColorList } from "@utils/colorList"
import { getPurchases } from "@utils/getPurchases"
import { BytesLike } from "ethers"
import { useAccount, useSignMessage, useSwitchNetwork } from "wagmi"
import { message } from "utils/signMessage"
import { useEthersProvider } from "@utils/ethers"
import { ProductCart } from "@lib/handleUpdateCart"
import { View } from "@components/modals/types"
import { useSIWE } from "@walletkit/wagmi-v1-link"

export type Purchase = {
  slicerId: string
  productId: string
  totalQuantity: string
  buyerCustomData: BytesLike
}

const AppContext = createContext<any>({
  connector: null,
  provider: null,
  account: "",
  isConnected: false,
  isSwitchingNetwork: false,
  isModalTrigger: false,
  isSigned: false,
  setIsSigned: null,
  signMessageAsync: null,
  isSignatureLoading: false,
  color1: colorList[0],
  color2: colorList[1],
  darkColor1: darkColorList[0],
  darkColor2: darkColorList[1],
  modalView: { name: "" },
  purchases: [],
  cart: [],
  setModalView: () => null,
  setPurchases: () => null,
  updateCart: () => null,
  shuffleColors: () => null,
  switchNetwork: () => null,
  setIsModalTrigger: () => null
})

export function AppWrapper({ children }) {
  const [isConnected, setIsConnected] = useState(false)
  const [isSigned, setIsSigned] = useState(false)
  const [isModalTrigger, setIsModalTrigger] = useState(0)
  const [modalView, setModalView] = useState<View>({ name: "" })
  const provider = useEthersProvider()
  const { switchNetwork, isLoading: isSwitchingNetwork } = useSwitchNetwork({
    chainId: Number(process.env.NEXT_PUBLIC_CHAIN_ID)
  })

  // Signature authentication
  const { signMessageAsync, isLoading: isSignatureLoading } = useSignMessage({
    message
  })

  const { address, connector } = useAccount()
  const { address: signerWallet } = useSIWE()

  const [color1, setColor1] = useState([])
  const [color2, setColor2] = useState([])
  const [darkColor1, setDarkColor1] = useState([])
  const [darkColor2, setDarkColor2] = useState([])
  const [purchases, setPurchases] = useState<Purchase[]>(null)
  const [cart, setCart] = useState<ProductCart[]>(
    (typeof window !== "undefined" &&
      JSON.parse(localStorage.getItem("cart"))) ||
      []
  )

  const shuffleColors = () => {
    const random1 = Math.floor(Math.random() * colorList.length)
    const random2 = Math.floor(Math.random() * colorList.length)
    setColor1(colorList[random1])
    setColor2(colorList[random2])
    setDarkColor1(darkColorList[random1])
    setDarkColor2(darkColorList[random2])

    let root = document.documentElement
    root.style.setProperty("--color1", colorList[random1][0])
    root.style.setProperty("--color2", colorList[random2][0])
    root.style.setProperty("--darkColor1", darkColorList[random1][0])
    root.style.setProperty("--darkColor2", darkColorList[random2][0])
  }

  const updateCart = (newCart: ProductCart[]) => {
    setCart(newCart)
    localStorage.setItem("cart", JSON.stringify(newCart))
  }

  useEffect(() => {
    setPurchases(null)
    setIsConnected(address && true)
    const buyer = signerWallet || address
    if (buyer) {
      getPurchases(buyer, setPurchases)
      if (buyer && localStorage.getItem("isSigned") == buyer) {
        setIsSigned(true)
      } else {
        setIsSigned(false)
        localStorage.removeItem("isSigned")
      }
    } else {
      localStorage.removeItem("isSigned")
    }
  }, [signerWallet, address])

  useEffect(() => {
    shuffleColors()
  }, [])

  return (
    <AppContext.Provider
      value={{
        connector,
        provider,
        account: address,
        isConnected,
        isSigned,
        setIsSigned,
        signMessageAsync,
        isSignatureLoading,
        color1,
        color2,
        darkColor1,
        darkColor2,
        modalView,
        setModalView,
        shuffleColors,
        purchases,
        setPurchases,
        cart,
        updateCart,
        switchNetwork,
        isSwitchingNetwork,
        isModalTrigger,
        setIsModalTrigger
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}

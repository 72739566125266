import React, { useState } from "react"
import { useAppContext } from "../context"
import handleRedeemProduct from "@utils/handleRedeemProduct"
import Spinner from "@components/icons/Spinner"
import ShoppingBag from "@components/icons/ShoppingBag"
import { RedeemTexts } from "@lib/handleUpdateCart"
import { useSIWE } from "@walletkit/wagmi-v1-link"

type Props = {
  slicerId: number
  productId: number
  productName: string
  dbId: number
  image: string
  uid: string
  creator: string
  texts: RedeemTexts
  shortcodes?: string[]
  redeemButton: boolean | undefined
}

const ViewPurchaseButton = ({
  slicerId,
  productId,
  dbId,
  productName,
  image,
  uid,
  creator,
  texts,
  shortcodes,
  redeemButton
}: Props) => {
  const { account, setModalView } = useAppContext()
  const { address: signerWallet } = useSIWE()

  const [loading, setLoading] = useState(false)

  return (
    <div
      className="relative cursor-pointer flex gap-2 items-center justify-center w-[88px] sm:w-40 text-white transition-colors duration-100 bg-blue-500 h-9 text-sm font-medium rounded-md nightwind-prevent hover:bg-blue-600 group"
      onClick={() =>
        handleRedeemProduct(
          signerWallet || account,
          dbId,
          slicerId,
          productId,
          productName,
          image,
          uid,
          creator,
          texts,
          setLoading,
          setModalView,
          shortcodes,
          redeemButton
        )
      }
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <p>
            View <span className="hidden sm:inline-block">purchase</span>
          </p>
          <ShoppingBag className="w-5 h-5 transition-transform duration-150 transform group-hover:-rotate-20" />
        </>
      )}
    </div>
  )
}

export default ViewPurchaseButton

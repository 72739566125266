import { PurchaseParamsStruct } from "types/typechain/ProductsModule"
import { BigNumber, ethers } from "ethers"
import { ProductCart } from "@lib/handleUpdateCart"
import { quoteParams } from "@utils/useEthUsd"
import { readContract } from "@wagmi/core"
import constants from "constants.json"
import PriceFeed from "artifacts/contracts/PriceFeed.sol/PriceFeed.json"
import { prepareWriteContract, writeContract } from "@wagmi/core"
import { productsModuleAddress } from "@lib/initProvider"
import ProductsModule from "artifacts/contracts/ProductsModule.sol/ProductsModule.json"
import { NewTransaction } from "@rainbow-me/rainbowkit/dist/transactions/transactionStore"
import { PublicClient } from "viem"

export const payProductsConfig = async (
  publicClient: PublicClient,
  buyer: string,
  productData: ProductCart[],
  account?: string
) => {
  const quote = await readContract({
    address:
      constants[process.env.NEXT_PUBLIC_CHAIN_ID][
        process.env.NEXT_PUBLIC_ENVIRONMENT
      ].addresses["PriceFeed"],
    abi: PriceFeed.abi,
    functionName: "getQuote",
    args: quoteParams
  })

  const ethUsd = quote as BigInt // Chainlink: Number(quote[1])
  let ethAmount: BigNumber
  let purchaseParams: PurchaseParamsStruct[] = []

  try {
    productData.forEach((product) => {
      const {
        slicerId,
        productId,
        quantity,
        price,
        currency,
        isUSD,
        extCallValue,
        buyerCustomData
      } = product

      const currentPrice = ethAmount || 0

      purchaseParams.push({
        buyer,
        slicerId,
        quantity,
        currency,
        productId,
        buyerCustomData
      })

      if (currency === ethers.constants.AddressZero) {
        const productPrice = isUSD
          ? BigNumber.from(price)
              .mul(BigNumber.from(10).pow(18)) // chainlink -> pow(24)
              .mul(102) // 2% overpayment to compensate for price fluctuations (repaid to buyer during tx)
              .div(100)
              .div(BigNumber.from(ethUsd))
              .add(extCallValue)
          : BigNumber.from(price).add(extCallValue)
        ethAmount = BigNumber.from(currentPrice).add(productPrice)

        // 5% overpayment to compensate for linear vrgda potential purchases during checkout (repaid to buyer during tx)
        if (
          product.externalAddress ==
            constants[process.env.NEXT_PUBLIC_CHAIN_ID][
              process.env.NEXT_PUBLIC_ENVIRONMENT
            ].strategies["Linear VRGDA"]?.toLowerCase() ||
          product.externalAddress ==
            constants[process.env.NEXT_PUBLIC_CHAIN_ID][
              process.env.NEXT_PUBLIC_ENVIRONMENT
            ].strategies["Logistic VRGDA"]?.toLowerCase()
        ) {
          ethAmount = BigNumber.from(ethAmount).mul(105).div(100)
        }
      }
    })
    const accountba = account || buyer

    const config = {
      address: productsModuleAddress,
      abi: ProductsModule.reducedAbi,
      functionName: "payProducts",
      args: [purchaseParams],
      value: ethAmount,
      account: accountba
    }

    // if smart wallet account != 0 and is the smart wallet
    const gasEstimate = await publicClient.estimateContractGas({
      ...config
    })

    config["gas"] = (gasEstimate * BigInt(3)) / BigInt(2)

    return config
  } catch (err) {
    console.log(err)

    throw err
  }
}

export const payProducts = async (
  publicClient: PublicClient,
  buyer: string,
  productData: ProductCart[],
  addRecentTransaction: (transaction: NewTransaction) => void,
  account?: string
) => {
  try {
    const config = await payProductsConfig(
      publicClient,
      buyer,
      productData,
      account
    )

    const { request } = await prepareWriteContract(config)

    const { hash } = await writeContract(request)

    addRecentTransaction({
      hash,
      description: "Checkout"
    })

    return hash
  } catch (err) {
    console.log(err)

    throw err
  }
}

export default payProducts
